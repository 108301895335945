import React, { Component } from 'react';
import logo from './DubberMSTeamsSmall.png';
import infoicon from './InfoIcon.png';
import copyIcon from './CopyIcon.png';

class CreatePolicy extends Component {
    constructor(props) {
        super(props);

        var policyName = "Dubber" + props.domainName + "RP";
        if (!props.playAnnouncements || props.recordVideo) {
            policyName = policyName + ".";
            if (!props.playAnnouncements) {
                policyName = policyName + "NA";
            }
            if (props.recordVideo) {
                policyName = policyName + "V";
            }
        }

        var securityGroupName = "MS Teams Dubber Recording Group";
        if (!props.playAnnouncements || props.recordVideo) {
            if (!props.playAnnouncements) {
                securityGroupName = securityGroupName + " No Announcements";
            }
            if (props.recordVideo) {
                securityGroupName = securityGroupName + " with Video";
            }
        }

        var psCommandCreatePolicy = "$complianceRecordingPolicy = New-CsTeamsComplianceRecordingPolicy " +
            " -Enabled $true -Description '" +
            policyName +
            "' -Identity '" +
            policyName +
            "'\n";

        var botAName = "dubberbotA" + props.portal;
        var botBName = "dubberbotB" + props.portal;

        var psCommandCreateAppInstance =
            "$onlineApplicationInstanceA = New-CsOnlineApplicationInstance -UserPrincipalName " +
            botAName +
            "@" +
            props.domainName +
            " -DisplayName " +
            botAName +
            " -ApplicationId " +
            props.botA +
            "\n$onlineApplicationInstanceB = New-CsOnlineApplicationInstance -UserPrincipalName " +
            botBName +
            "@" +
            props.domainName +
            " -DisplayName " +
            botBName +
            " -ApplicationId " +
            props.botB + "\n" +
            "Start-Sleep -Seconds 5\n" +
            "Sync-CsOnlineApplicationInstance -ObjectId $onlineApplicationInstanceA.ObjectId -ApplicationId $onlineApplicationInstanceA.ApplicationId\n" +
            "Sync-CsOnlineApplicationInstance -ObjectId $onlineApplicationInstanceB.ObjectId -ApplicationId $onlineApplicationInstanceB.ApplicationId\n ";

        var psCommandGetAppInstance = "$onlineApplicationInstanceA = Get-CsOnlineApplicationInstance -Identity " +
            botAName + "@" + props.domainName + "\n$onlineApplicationInstanceB = Get-CsOnlineApplicationInstance -Identity " +
            botBName + "@" + props.domainName + "\n";

        var psCommandCreateRecordingGroup = "$recordingGroup = New-AzureADGroup -Description \"" +
            securityGroupName +
            "\" -DisplayName \"" +
            securityGroupName +
            "\" -MailEnabled $false -SecurityEnabled $true -MailNickName 'msteamsrecording'\n";

        var requireRecording = props.alwaysAllowCalls ? "$false" : "$true";

        var psCommandGroupPolicyAssignment =
            "$complianceRecordingApplicationA = New-CsTeamsComplianceRecordingApplication " +
            " -Parent " +
            policyName +
            " -Id $onlineApplicationInstanceA.ObjectId -RequiredBeforeMeetingJoin " +
            requireRecording +
            " -RequiredDuringMeeting " +
            requireRecording +
            " -RequiredBeforeCallEstablishment " +
            requireRecording +
            " -RequiredDuringCall " +
            requireRecording +
            " -ConcurrentInvitationCount 1\n$complianceRecordingApplicationB = New-CsTeamsComplianceRecordingApplication " +
            " -Parent " +
            policyName +
            " -Id $onlineApplicationInstanceB.ObjectId -RequiredBeforeMeetingJoin " +
            requireRecording +
            " -RequiredDuringMeeting " +
            requireRecording +
            " -RequiredBeforeCallEstablishment " +
            requireRecording +
            " -RequiredDuringCall " +
            requireRecording +
            " -ConcurrentInvitationCount 1\n$complianceRecordingPolicy = Set-CsTeamsComplianceRecordingPolicy " +
            " -Identity " +
            policyName + " -ComplianceRecordingApplications @($complianceRecordingApplicationA, $complianceRecordingApplicationB)\nNew-CsGroupPolicyAssignment -GroupId $recordingGroup.ObjectId -PolicyType TeamsComplianceRecordingPolicy -PolicyName " +
            policyName + " -Rank 1\n";

        this.state = {
            showHideHelpstep6li2: false,
            domainName: this.props.domainName,
            tenantId: this.props.tenentId,
            playAnnouncements: this.props.playAnnouncements,
            alwaysAllowCalls: this.props.alwaysAllowCalls,
            recordVideo: this.props.recordVideo,
            psCommandCreatePolicy: psCommandCreatePolicy,
            psCommandCreateAppInstance: psCommandCreateAppInstance,
            psCommandGetAppInstance: psCommandGetAppInstance,
            psCommandCreateRecordingGroup: psCommandCreateRecordingGroup,
            psCommandGroupPolicyAssignment: psCommandGroupPolicyAssignment,
            botAName: botAName,
            botBName: botBName
        };
        this.showHideHelpstep6li2 = this.showHideHelpstep6li2.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (typeof nextProps.domainName != "undefined") {
            this.setState({ domainName: nextProps.domainName });
        }
        if (typeof nextProps.tenantId != "undefined") {
            this.setState({ tenantId: nextProps.tenantId });
        }
        if (typeof nextProps.playAnnouncements != "undefined") {
            this.setState({ playAnnouncements: nextProps.playAnnouncements });
        }
        if (typeof nextProps.alwaysAllowCalls != "undefined") {
            this.setState({ alwaysAllowCalls: nextProps.alwaysAllowCalls });
        }
        if (typeof nextProps.recordVideo != "undefined") {
            this.setState({ recordVideo: nextProps.recordVideo });
        }

        var policyName = "Dubber" + nextProps.domainName + "RP";
        if (!nextProps.playAnnouncements || nextProps.recordVideo) {
            policyName = policyName + ".";
            if (!nextProps.playAnnouncements) {
                policyName = policyName + "NA";
            }
            if (nextProps.recordVideo) {
                policyName = policyName + "V";
            }
        }

        var securityGroupName = "MS Teams Dubber Recording Group";
        if (!nextProps.playAnnouncements || nextProps.recordVideo) {
            if (!nextProps.playAnnouncements) {
                securityGroupName = securityGroupName + " No Announcements";
            }
            if (nextProps.recordVideo) {
                securityGroupName = securityGroupName + " with Video";
            }
        }

        var psCommandCreatePolicy = "$complianceRecordingPolicy = New-CsTeamsComplianceRecordingPolicy " +
            " -Enabled $true -Description '" +
            policyName +
            "' -Identity '" +
            policyName +
            "'\n";

        var botAName = "dubberbotA" + nextProps.portal;
        var botBName = "dubberbotB" + nextProps.portal;

        var psCommandCreateAppInstance =
            "$onlineApplicationInstanceA = New-CsOnlineApplicationInstance -UserPrincipalName " +
            botAName +
            "@" +
            nextProps.domainName +
            " -DisplayName " +
            botAName +
            " -ApplicationId " +
            nextProps.botA +
            "\n$onlineApplicationInstanceB = New-CsOnlineApplicationInstance -UserPrincipalName " +
            botBName +
            "@" +
            nextProps.domainName +
            " -DisplayName " +
            botBName +
            " -ApplicationId " +
            nextProps.botB + "\n" +
            "Start-Sleep -Seconds 5\n" +
            "Sync-CsOnlineApplicationInstance -ObjectId $onlineApplicationInstanceA.ObjectId -ApplicationId $onlineApplicationInstanceA.ApplicationId\n" +
            "Sync-CsOnlineApplicationInstance -ObjectId $onlineApplicationInstanceB.ObjectId -ApplicationId $onlineApplicationInstanceB.ApplicationId\n ";

        var psCommandGetAppInstance = "$onlineApplicationInstanceA = Get-CsOnlineApplicationInstance -Identity " +
            botAName + "@" + nextProps.domainName + "\n$onlineApplicationInstanceB = Get-CsOnlineApplicationInstance -Identity " +
            botBName + "@" + nextProps.domainName + "\n";

        var psCommandCreateRecordingGroup = "$recordingGroup = New-AzureADGroup -Description \"" +
            securityGroupName +
            "\" -DisplayName \"" +
            securityGroupName +
            "\" -MailEnabled $false -SecurityEnabled $true -MailNickName 'msteamsrecording'\n";

        var requireRecording = nextProps.alwaysAllowCalls ? "$false" : "$true";

        var psCommandGroupPolicyAssignment =
            "$complianceRecordingApplicationA = New-CsTeamsComplianceRecordingApplication " +
            " -Parent " +
            policyName +
            " -Id $onlineApplicationInstanceA.ObjectId -RequiredBeforeMeetingJoin " +
            requireRecording +
            " -RequiredDuringMeeting " +
            requireRecording +
            " -RequiredBeforeCallEstablishment " +
            requireRecording +
            " -RequiredDuringCall " +
            requireRecording +
            " -ConcurrentInvitationCount 1\n$complianceRecordingApplicationB = New-CsTeamsComplianceRecordingApplication " +
            " -Parent " +
            policyName +
            " -Id $onlineApplicationInstanceB.ObjectId -RequiredBeforeMeetingJoin " +
            requireRecording +
            " -RequiredDuringMeeting " +
            requireRecording +
            " -RequiredBeforeCallEstablishment " +
            requireRecording +
            " -RequiredDuringCall " +
            requireRecording +
            " -ConcurrentInvitationCount 1\n$complianceRecordingPolicy = Set-CsTeamsComplianceRecordingPolicy " +
            " -Identity " +
            policyName + " -ComplianceRecordingApplications @($complianceRecordingApplicationA, $complianceRecordingApplicationB)\nNew-CsGroupPolicyAssignment -GroupId $recordingGroup.ObjectId -PolicyType TeamsComplianceRecordingPolicy -PolicyName " +
            policyName + " -Rank 1\n";
        this.setState({
            psCommandCreatePolicy: psCommandCreatePolicy,
            psCommandCreateAppInstance: psCommandCreateAppInstance,
            psCommandGetAppInstance: psCommandGetAppInstance,
            psCommandCreateRecordingGroup: psCommandCreateRecordingGroup,
            psCommandGroupPolicyAssignment: psCommandGroupPolicyAssignment,
            botAName: botAName,
            botBName: botBName
        });


    }

    copyCreatePolicyTextArea(event) {
        var copyText = document.getElementById("createPolicyTextArea");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand("copy");
    }

    copyCreateAppInstanceTextArea(event) {
        var copyText = document.getElementById("createAppInstanceTextArea");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand("copy");
    }

    copyGetAppInstanceTextArea(event) {
        var copyText = document.getElementById("getAppInstanceTextArea");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand("copy");
    }

    copyRecordingGroupTextArea(event) {
        var copyText = document.getElementById("createCreateRecordingGroupInstanceTextArea");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand("copy");
    }

    copyGroupPolicyAssignmentTextArea(event) {
        var copyText = document.getElementById("createRecordingGroupTextArea");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand("copy");
    }

    showHideHelpstep6li2(event) {
        this.setState({ showHideHelpstep6li2: !this.state.showHideHelpstep6li2 });
    }

    donothing(event) {

    }

    render() {
        return (
            <div className="no-top-margin">
                <div className="ui center aligned container head">
                    <div className="stepheader">
                        <div className="step">Step 4</div>
                        <img src={logo} alt="Dubber MS Teams" className="small-logo" />
                        <div className="step-back"><button onClick={this.props.cancel}>&lt; Back</button></div>
                    </div>
                    <div className="ui aligned container start">
                        <div className="step-header">
                            <h3>Create Compliance Recording Policy</h3>
                            <p>
                                The Dubber Recorder consists of two highly available recorder groups in each region.  The following PowerShell commands will create the recording policy, map the Dubber Applications to Applications in your tenant, create the recording Security Group and assign the Security Group to the Recording Policy.  If you have already created the recording policy, click next to select the users to record.
                            </p>
                        </div>
                        <div className="instructions">
                            <img src={infoicon} alt="i" />
                            <br />
                            <ol>
                                <li>Create the compliance recording policy</li>
                            </ol>
                        </div>
                        <div className="column-half">
                            <div className="space20px">
                                <textarea id="createPolicyTextArea" className="createPolicy" value={this.state.psCommandCreatePolicy} onChange={this.donothing} >
                                </textarea>
                                <button className="copyText" onClick={this.copyCreatePolicyTextArea} ><img className="copyIcon" src={copyIcon} alt="" />Copy</button>
                            </div>
                            <sub>This will result in a warning message.  This is rectified in step 4.</sub>
                        </div>
                        <div className="external-links">
                            <sub>View the Microsoft Documentation for<a className="download" target="_blank" rel="noopener noreferrer" href="https://docs.microsoft.com/en-us/powershell/module/skype/new-csteamscompliancerecordingpolicy?view=skype-ps">New-CsTeamsComplianceRecordingPolicy</a></sub>
                        </div>
                        <hr />
                        <div className="instructions">
                            <ol start="2">
                                <li>Create Application Instances for the Dubber Recorders</li>
                            </ol>
                        </div>
                        <div className="column-half">
                            <div className="space20px">
                                <textarea id="createAppInstanceTextArea" className="createAppInstance" value={this.state.psCommandCreateAppInstance} onChange={this.donothing} ></textarea>
                                <button className="copyText" onClick={this.copyCreateAppInstanceTextArea} ><img className="copyIcon" src={copyIcon} alt="" />Copy</button>
                            </div>
                        </div>
                        <div className="error-help">
                            If you get an error message stating 'UserPrincipalName is already used by another application
                            <button id="helpStep6Li2" className="expand" onClick={this.showHideHelpstep6li2}>expand</button>
                            <div id="helpTextForHelpStep6Li2" style={{
                                display: this.state.showHideHelpstep6li2 ? "block" : "none", padding: 0
                            }}>
                                <div className="error-instructions">
                                    This happens when the email addresses associated with the application instances ({this.state.botAName + "@" + this.state.domainName}, {this.state.botBName + "@" + this.state.domainName}) are already in use.  Run the following command instead to continue.
                                </div>
                                <div className="error-column-half">
                                    <textarea id="getAppInstanceTextArea" className="createAppInstance" value={this.state.psCommandGetAppInstance} onChange={this.donothing}></textarea>
                                    <button className="copyText" onClick={this.copyGetAppInstanceTextArea} ><img className="copyIcon" src={copyIcon} alt="" />Copy</button>
                                </div>
                            </div>
                        </div>
                        <div className="external-links">
                            <sub>View the Microsoft Documentation for<a className="download" target="_blank" rel="noopener noreferrer" href="https://docs.microsoft.com/en-us/powershell/module/skype/new-csonlineapplicationinstance?view=skype-ps">New-CsOnlineApplicationInstance</a></sub>
                        </div>
                        <hr />
                        <div className="instructions">
                            <ol start="3">
                                <li>Create the Recording Group</li>
                            </ol>
                        </div>
                        <div className="column-half">
                            <div className="space20px">
                                <textarea id="createCreateRecordingGroupInstanceTextArea" className="createAppInstance" value={this.state.psCommandCreateRecordingGroup} onChange={this.donothing}>
                                </textarea>
                                <button className="copyText" onClick={this.copyRecordingGroupTextArea} ><img className="copyIcon" src={copyIcon} alt="" />Copy</button>
                            </div>
                        </div>
                        <div className="external-links">
                            <sub>View the Microsoft Documentation for<a className="download" target="_blank" rel="noopener noreferrer" href="https://docs.microsoft.com/en-us/powershell/module/azuread/new-azureadgroup?view=azureadps-2.0">New-AzureADGroup</a></sub>
                        </div>
                        <hr />
                        <div className="instructions">
                            <ol start="4">
                                <li>Assign Group to Recording Policy</li>
                            </ol>
                        </div>
                        <div className="column-half">
                            <div className="space20px">
                                <textarea id="createRecordingGroupTextArea" className="createRecordingGroup" value={this.state.psCommandGroupPolicyAssignment} onChange={this.donothing}>

                                </textarea>
                                <button className="copyText" onClick={this.copyGroupPolicyAssignmentTextArea} ><img className="copyIcon" src={copyIcon} alt="" />Copy</button>
                            </div>
                        </div>
                        <div className="external-links">
                            <sub>View the Microsoft Documentation for<a className="download" target="_blank" rel="noopener noreferrer" href="https://docs.microsoft.com/en-us/powershell/module/skype/new-csteamscompliancerecordingapplication?view=skype-ps">New-CsTeamsComplianceRecordingApplication</a></sub><br />
                            <sub>View the Microsoft Documentation for<a className="download" target="_blank" rel="noopener noreferrer" href="https://docs.microsoft.com/en-us/powershell/module/skype/set-csteamscompliancerecordingpolicy?view=skype-ps">Set-CsTeamsComplianceRecordingPolicy</a></sub><br />
                            <sub>View the Microsoft Documentation for<a className="download" target="_blank" rel="noopener noreferrer" href="https://docs.microsoft.com/en-us/powershell/module/teams/new-csgrouppolicyassignment?view=teams-ps">New-CsGroupPolicyAssignment</a></sub>
                        </div>

                    </div>
                    <div className="link">
                        <button onClick={this.props.next} className="next-link" >Next</button>
                    </div>

                </div>

            </div>

        );
    }
}

export { CreatePolicy }