import React from 'react';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import logo from './DubberMSTeamsSmall.png';
import infoicon from './InfoIcon.png';
import axios from "axios";
import { Ring } from "react-awesome-spinners";
import { UserSetting } from "./UserSetting";
import Select from 'react-select'

class UserSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            config: null,
            page: 1,
            count: 20
        }

        this.loadData = this.loadData.bind(this);
        this.saveUserSettings = this.saveUserSettings.bind(this);
        this.previousPageClicked = this.previousPageClicked.bind(this);
        this.nextPageClicked = this.nextPageClicked.bind(this);
        this.countChanged = this.countChanged.bind(this);
    }

    componentDidMount() {
        this.loadData(this.state.count, this.state.page);
    }

    loadData(count, page) {
        this.setState({
            loading: true
        });
        axios.get(this.props.configurationUrl + "/" + this.props.tenantId + "/users?slug=" + this.props.slug + "&region=" + this.props.portalCode + "&count=" + count + "&page=" + page,
            {
                headers: {
                    'Authorization': 'Jwt ' + this.props.accessToken
                }
            }).then((taskResponse) => {

                var usersConfig = [];
                var showVideoOption = taskResponse.data.videoEnabled;
                if (taskResponse.data !== undefined && taskResponse.data.users !== undefined) {
                    taskResponse.data.users.forEach((user) => {
                        var permissionPauseResumeCall = this.getBooleanSettingValue(user, "permissionPauseResumeCall");
                        var permissionDiscardCall = this.getBooleanSettingValue(user, "permissionDiscardCall");
                        var recordVideoEnabled = this.getBooleanSettingValue(user, "recordVideo");
                        var recordScreenShareEnabled = this.getVideoLegacySettingValue(user, "recordScreenShare", recordVideoEnabled);
                        var onDemandRecording = this.getBooleanSettingValue(user, "onDemandRecording");
                        var recordCallClassifications = this.getStringSettingValue(user, "recordCallClassifications");
                        // "all,externalParties,meetings,pstn"
                        var recordAllCalls = null;
                        var recordExternalPartyCalls = null;
                        var recordMeetings = null;
                        var recordPSTN = null;
                        if (recordCallClassifications !== null) {
                            recordAllCalls = recordCallClassifications.includes("all");
                            recordExternalPartyCalls = recordCallClassifications.includes("externalParties");
                            recordMeetings = recordCallClassifications.includes("meetings");
                            recordPSTN = recordCallClassifications.includes("pstn");
                        }

                        var displayName = this.getDisplayName(user);
                        var userSlug = this.getUserSlug(user);

                        usersConfig.push({
                            externalId: user.microsoft.userId,
                            displayName: displayName,
                            permissionPauseResumeCall: permissionPauseResumeCall,
                            permissionDiscardCall: permissionDiscardCall,
                            recordVideo: recordVideoEnabled,
                            recordScreenShare: recordScreenShareEnabled,
                            onDemandRecording: onDemandRecording,
                            userSlug: userSlug,
                            showVideo: showVideoOption,
                            recordAllCalls: recordAllCalls,
                            recordExternalPartyCalls: recordExternalPartyCalls,
                            recordMeetings: recordMeetings,
                            recordPSTN: recordPSTN,
                            dubPointStatus: user.dubPoint.status,
                            dubPointId: user.dubPoint.id,
                            dubPointExternalGroup: user.dubPoint.externalGroup,
                            dubPointIsExternal: user.dubPoint.externalGroup !== this.props.tenantId

                        });
                    });
                }
                this.setState({ config: usersConfig, tenantSettings: taskResponse.data.tenantSettings, loading: false, lastPage: usersConfig.length < count, firstPage: page === 1 });

        }).catch(error => {
            console.log(error);
            this.setState({ config: [], loading: false, errorMessage: error.data });
        });

    }

    previousPageClicked(event) {
        var page = this.state.page - 1;
        if (page < 1) page = 1;
        this.setState({ page: page });
        this.loadData(this.state.count, page);
    }

    nextPageClicked(event) {
        var page = this.state.page + 1;
        this.setState({ page: page });
        this.loadData(this.state.count, page);
    }

    getBooleanSettingValue(user, settingName) {
        var result = null;
        if (user.settings !== null && user.settings !== undefined) {
            var setting = user.settings.find(s => s.name === settingName);
            if (setting !== null && setting !== undefined) {
                result = setting.value === "true";
            }
        }
        return result;
    }

    getVideoLegacySettingValue(user, settingName, defaultValue) {
        if (user.settings !== null && user.settings !== undefined) {
            var setting = user.settings.find(s => s.name === settingName);
            if (setting !== null && setting !== undefined) {
                return setting.value;
            }
        }
        return defaultValue;
    }

    getStringSettingValue(user, settingName) {
        var result = null;
        if (user.settings !== null && user.settings !== undefined) {
            var setting = user.settings.find(s => s.name === settingName);
            if (setting !== null && setting !== undefined) {
                result = setting.value;
            }
        }
        return result;
    }

    getDisplayName(user) {
        var result = this.getStringSettingValue(user, "displayName");
        if (result === null || (result.length === 0 && user.dubPoint)) {
            result = user.dubPoint.label;
        }
        return result;
    }

    getUserSlug(user) {
        var result = this.getStringSettingValue(user, "userSlug");
        if (result == null || (result.length === 0 && user.dubPoint)) {
            result = user.dubPoint.user;
        }
        return result;
    }

    saveUserSettings(userId, settingName, settingValue) {
        this.setState({ loading: true });
        let body = {
            settingValue: settingValue,
            slug: this.props.slug,
            region: this.props.portalCode
        };
        axios.put(this.props.configurationUrl + "/" + this.props.tenantId +
            "/user/" + userId + "/setting/" +
            settingName, body,
            {
                headers: {
                    'Authorization': 'Jwt ' + this.props.accessToken
                }
            }).then((response) => {
            this.setState({ loading: false });
        }).catch((error) => {
            console.log(error);
            this.setState({ loading: false });
        });
    }

    countChanged(selectedOption) {
        this.setState({ count: selectedOption.value });
    }

    render() {
        var configBlock = <div></div>;
        if (this.state.config) {
            configBlock = this.state.config.map((user) => {
                return <UserSetting key={user.dubPointId}
                    user={user}
                    saveUserSettings={this.saveUserSettings}
                    showVideo={user.showVideo}
                    tenantSettings={this.state.tenantSettings}
                    tenantId={this.props.tenantId} />;
            });
        }
        //const options = [
        //    { value: 10, label: 'Count 10' }, { value: 20, label: 'Count 20' }, { value: 50, label: 'Count 50' }
        //];

        return (
            <div id="main" className="ui text container transition">
                <div id="usersettingsheader" className="wizard-step">
                    <div className="no-top-margin">
                        <div className="ui center aligned container head">
                            <div className="stepheader">
                                <div className="step">User Settings</div>
                                <img src={logo} alt="Dubber MS Teams" className="small-logo" />
                                <div className="step-back"><NavLink tag={Link} to="/">&lt; Back</NavLink>
                                </div>
                                <div className="ui aligned container start">
                                    <div className="step-header">
                                        <h3>Edit User Settings</h3>
                                        <p>
                                            This page allows the editing of settings related to Microsoft Teams Compliance Recording
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="ui aligned container start">
                                <div className="divTable">
                                    <div className="divTableRow">
                                        <div className="divTableHead">
                                            User Name
                                        </div>
                                        <div className="divTableHead">Settings
                                            <div className="page-control">
                                                <button className="next-link" id="previousPageButton" disabled={this.state.firstPage} onClick={this.previousPageClicked}>Prev</button>
                                                Page {this.state.page}
                                                {/*Count <Select value={this.state.count} options={options} onChange={this.countChanged} />*/}
                                                <button className="next-link" id="nextPageButton" disabled={this.state.lastPage} onClick={this.nextPageClicked}>Next</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="usertable">
                                    <div className="divTable">
                                        <div className="divTableRow">
                                            <div className="divTableCell"></div>
                                            <div className="divTableCell"></div>
                                        </div>
                                        {configBlock}
                                    </div>
                                </div>
                                <label id="errorMessageLabel" className="errorText">{this.state.errorMessage}</label>
                            </div>
                            <div className="link">
                                <NavLink tag={Link} className="next-link" to="/">Back</NavLink>
                            </div>
                            <div className={this.state.loading ? "spinner-div" : "spinner-hidden"}>
                                <div className="middle-of-page">
                                    <Ring />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>);

    }
}

export {UserSettings}